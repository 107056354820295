import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable , of } from "rxjs";
import { DataService } from '../services/data.service';

@Injectable()
export class DeGuard implements CanActivate {
    constructor(
        private _router: Router, 
        private data: DataService
    ) {}
    
    canActivate():Observable<boolean>{
        let token = this.data.getCookiesByName('token');
        this.data.changeToken(token);
        if(token){
            this._router.navigate(['/dashboard/application-data']);
            return of(false);
        } else {
            return of(true);
        }
    }
}