import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable , of } from "rxjs";
// import { of} from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router, 
        private data: DataService
    ) {}
	
    canActivate():Observable<boolean>{
    	let token = this.data.getCookiesByName('token');
        this.data.changeToken(token);
    	if(token){
            return of(true);
        } else {
            this._router.navigate(['/login']);
            return of(false);
        }
    }
}