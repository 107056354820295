import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  accountLogin: { username: string; password: string } = {
    username: null,
    password: null
  };
  emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  model: any = {};

  constructor(
    public _app: AppService,
    private _router: Router,
    private _data: DataService
  ) {}


  ngOnInit() {
  }

  doLogin(){
        this._app.doLogin(this.accountLogin).subscribe(
            (res)=>{console.log(res) , 
                this._data.setCookieByName("token" , res["access_token"]);
                this._data.toastrShow("Logged In!" , 'info'),
                this._router.navigate(['/dashboard/application-data']);
            },
            (err)=>{console.log(err)},
        );
    }
}
