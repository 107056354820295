import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal'
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../../services/app.service' ;

@Component({
  	selector: 'app-obs-modal',
  	templateUrl: './obs-modal.component.html',
  	styleUrls: ['./obs-modal.component.scss']
})
export class ObsModalComponent implements OnInit {
  	// vars for showEvents
	title                     : string;
	options                   : any ;
	showLogNewTokenAllocation : any ;
	showTokenDelivered        : any ;

	type                      : 
        'standardServiceConsumptionLimit' | 
        'showEvents' | 
        'updateApplicationData' | 
        'updateMerchantRedemption' | 
        'createdBy' | 
        'updatedBy' | 
        'clients' | 
        'client' |
        'stripeTransactionInfo' |
        'clientSubscriptionInfo' |
        'apiConsumptionHistory' |
        'standard-checks-update' |
        'update-tax'  |
        'create-tax'  |
        'update-currency'  |
        'create-currency' |
        'stp-config' | 
        'stp-pending-review'
        | "update-language"
        |  "create-language"
        | "create-theme"
        | "update-theme"
        | "update-email"
        | "create-email"
        | "assign-plan";

	public onClose            = new BehaviorSubject<any>({});
    updateApplicationData     : any;

	// vars for updateApplicationData
	applicationData           : any;
    obsData                   : any;

	// var for updateMerchantRedemption
	merchantData              : any ;
  	constructor(
  		public modalService: BsModalService , 
  		public bsModalRef: BsModalRef,
        private _app : AppService
  	) { }

  	ngOnInit() {
  		setTimeout(() => {
            if(Array.isArray(this.applicationData)){
                this.obsData = [];
                this.obsData = [...this.applicationData];
            }else{
                this.obsData = {};
                this.obsData = Object.assign({}, this.applicationData);
            }
            if(this.type == 'updateApplicationData'){
                this.updateApplicationData = this.obsData.dataValue[0];
            }
            console.log(this.obsData);
  		}, 500);
  	}

  	doUpdateAppData(){
        let data = {
            dataKey   : this.obsData.dataKey,
            dataValue : this.updateApplicationData
        };
        this._app.updateApplicationConfigData(data).subscribe((response)=>{
            this.obsData.dataValue[0] = this.updateApplicationData;
            this.bsModalRef.hide();
        }, (error)=>{
        });
	}

    updateChecks(){
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }

    updateTax(){
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }

    updateCurrency(){
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }

    closeModal(){
        this.onClose.next({
            updated : false
        });
        this.bsModalRef.hide();
    }

    updateStpStatus(){
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }
    updateLanguage(){
        console.log(this.obsData);
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }
    createTheme(){
        console.log(this.obsData);
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }
    updateTheme(){
        console.log(this.obsData);
        this.onClose.next({
            data : this.obsData,
            updated : true
        });
        this.bsModalRef.hide();
    }
    parseJson(data){
        return JSON.parse(data);
    }
    capitalize(data){
        return data.split('_').join(' ').replace(/(\b\w)/gi,function(m){return m.toUpperCase();})
    }
    // selectedcountry : any;
    // countries : any[] = [];
    // buildQueryCountry : any = {
    //     "countryName": null,
    //     "page"       : 0,
    //     "size"       : 20,
    //     "sort"       : "asc"
    // }
    // dropdownSettings: any = {
    //     singleSelection: true,
    //     idField: 'countryCode',
    //     textField: 'countryName',
    //     allowSearchFilter: true
    // };
    // getCountries(countryName){
    //     this.buildQueryCountry.countryName = countryName;
    //     this._app.getCountries(this.buildQueryCountry).subscribe((response) => {
    //         if(response.data.content.length){
    //             this.countries = response.data.content;
    //             console.log(this.countries)
    //         }
    //     },(err) => {
    //     });
    // }
}
