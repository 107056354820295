import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from "../services/data.service";
@Injectable()
export class ICOInterceptor implements HttpInterceptor {

  constructor(
    private _data: DataService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Accept': 'application/json'
    };
    let access_token = this._data.getCookiesByName('token'); //multipart/form-data
    if (access_token) {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
      headersConfig["Content-Type"] = `application/json`;
    }
    else {
      // old auth token,NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5

      headersConfig["Content-Type"] = `application/x-www-form-urlencoded`;
      headersConfig["Authorization"] = ` Basic N2VmMzdkMGUxZjMzYWU2ZTkwMzdhZmYwY2IyZTNhMTgzMjE3OTYzOTNjODNlMDhiMmYwNTM3YzE5YjY5NjI2NjoxYmQ2Y2Q1MTdhMDUwZGEyMWY4MDQ1Njk0MWFiMTU0NjU2MDhlZjFmMTNmYWQ1MzA5NTc5ZDg5NGFmYTc3OWFm`;
    }
    // const xsrfcokkie = document.cookie
    //   .split("; ")
    //   .find((row) => row.startsWith("XSRF-TOKEN="))
    // if (xsrfcokkie && request.method !== 'GET') {
    //   headersConfig["X-XSRF-TOKEN"] = xsrfcokkie.split("=")[1];
    // }
    // // console.log(request);
    // request = request.clone({
    //   setHeaders: headersConfig,
    //   withCredentials: true
    // });

    /// dev

    request = request.clone({
      setHeaders: headersConfig,
    });

    return next.handle(request);
  }
}