import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
import { AppService} from '../../services/app.service' ;
import { DataService } from '../../services/data.service';
import { first } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  title : string ;
  routeSubscription      : any ;
  currentRoute        : any ;

  constructor(
    public _app : AppService,
    private _router : Router,
    private data : DataService
  	) {
    this.routeSubscription = this._router.events
          .pipe(filter((event : any) => event instanceof NavigationEnd))
          .subscribe((event: any) => {
          if (event instanceof NavigationEnd ) {
            this.currentRoute = event.url ;
            this.title = this.data.getTitle(this.currentRoute);
          }
        });
  }

  ngOnInit() {
  }

  doLogout(){
    this.data.removeAllCookie();
    this._router.navigate(['/login']);
    // this._app.doLogout()
    // .subscribe(
    //     (res)=> {
    //         console.log(res);
    //         this.data.removeAllCookie();
    //         this.data.toastrShow(res.message, 'info');
    //         this._router.navigate(['/login']);
    //      },
    //      (err)=> { this.data.toastrShow(err.message, 'error') , console.log(err)}
    //   );
   }

}
